import React, { useState } from "react";
import styles from "./Signup.module.css";
import GoogleSvg from "../../../assets/google.svg";
import { TextField } from "@mui/material";
import styled from "styled-components";
import EllipseSvg from "../../../assets/EllipseMain.svg";
import EllipseSmallSvg from "../../../assets/EllipseSmall.svg";
import apiServiceHandler from "../../../service/apiService";
import { Link, useNavigate } from "react-router-dom";
import usePasswordVisible from "../../../utils/passwordVisible";
import toast from "react-hot-toast";
import FrameImg from "../../../assets/Frame.png";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#9ca3af",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      border: "0.8px solid #374151",
    },
  },
});

const Signup = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    re_password: "",
  });
  const [visible, Icon, toggleVisibility] = usePasswordVisible();

  const navigate = useNavigate();
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { email, password, re_password } = formData;

    if (!email || !password || !re_password) {
      toast.error("All Fields are Required!");
      return;
    }

    if (password !== re_password) {
      toast.error("Passwords do not match.");
      return;
    }

    try {
      let body = {
        email,
        password,
      };

      const response = await apiServiceHandler(
        "POST",
        "api/auth/send/email/otp",
        body
      );

      if (response.status) {
        toast.success(response.message, {
          duration: 2000,
          position: "top-center",
          iconTheme: {
            primary: "#15803d",
            secondary: "#fff",
          },
          ariaProps: {
            role: "status",
            "aria-live": "polite",
          },
        });
        setTimeout(() => {
          navigate("/verify-otp", {
            state: {
              email,
              jwtSecret: response.secret,
            },
          });
        }, 2000); // Navigate after 2 seconds
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again later.");
    }
  };

  return (
    <div className={styles.login}>
      <div className={styles.login_left}>
        <div className={styles.login_left_header}>
          <h1>Enter your Details</h1>
        </div>
        <div className={styles.ellipses}>
          <div className={styles.ellipses_small}>
            <img src={EllipseSmallSvg} />
          </div>
          <div className={styles.ellipses_main}>
            <img src={EllipseSvg} />
          </div>
        </div>
      </div>
      <div className={styles.login_right}>
        <div className={styles.login_form}>
          <div className={styles.brand_logo}>
            <img src={FrameImg} alt="" />
          </div>
          <div className={styles.login_form_heading}>
            <h1>Signup</h1>
          </div>

          <div className={styles.textField}>
            <CssTextField
              sx={{ width: "100%" }}
              label="Email Address"
              name="email"
              id="fullWidth"
              required
              onChange={handleChange}
              value={formData.email}
            />
          </div>

          <div className={styles.textField}>
            <CssTextField
              sx={{ width: "100%" }}
              label="Password"
              id="fullWidth"
              name="password"
              type="password"
              required
              onChange={handleChange}
              value={formData.password}
            />
          </div>

          <div className={styles.password_textField}>
            <CssTextField
              sx={{ width: "100%" }}
              label="Verfiy Password"
              type={visible ? "text" : "password"}
              name="re_password"
              id="fullWidth"
              required
              onChange={handleChange}
              value={formData.re_password}
            />
            <span className={styles.icon} onClick={toggleVisibility}>
              <Icon />
            </span>
          </div>

          <div className={styles.button}>
            <button onClick={handleSubmit}>CONTINUE</button>
          </div>
          <div className={styles.line}>
            <div className={styles.border_left}></div>
            <div className={styles.line_or}>OR</div>
            <div className={styles.border_right}></div>
          </div>
          <div className={styles.google_button}>
            <button>
              CONTINUE WITH GOOGLE <img src={GoogleSvg} alt="" />{" "}
            </button>
          </div>
          <div>
              Already have an Account?<span onClick={() => navigate("/login")} className={styles.login_option}>Login</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
