import React, { useState, useEffect } from "react";
import styles from "./VerifyOtp.module.css";
import { TextField } from "@mui/material";
import styled from "styled-components";
import EllipseSvg from "../../../assets/EllipseMain.svg";
import EllipseSmallSvg from "../../../assets/EllipseSmall.svg";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import apiServiceHandler from "../../../service/apiService";
import { MuiOtpInput } from "mui-one-time-password-input";
import FrameImg from "../../../assets/Frame.png";

const MuiOtpInputStyled = styled(MuiOtpInput)`
  display: flex;
  gap: 30px;
  max-width: 60%;
  margin-inline: auto;
`;

const Signup = () => {
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [jwtSecret, setJwtSecret] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(10);

  useEffect(() => {
    if (location.state && location.state.email && location.state.jwtSecret) {
      setEmail(location.state.email);
      setJwtSecret(location.state.jwtSecret);
    } else {
      console.error(
        "Email or jwtSecret not found in location state:",
        location.state
      );
    }
  }, [location.state]);

  const handleOtpChange = (newValue) => {
    setOtp(newValue);
  };

  const handleOtpVerification = async () => {
    try {
      const response = await apiServiceHandler(
        "POST",
        "api/auth/verify/email/otp",
        { email, otp, jwtSecret }
      );

      if (response.status) {
        localStorage.setItem("JWT_TOKEN", response.secret);
        toast.success("OTP verification successfull!");
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("email", email);
        setTimeout(() => {
          navigate("/address");
        }, 2000); // Navigate after 2 seconds
      } else {
        toast.error(response.message); // OTP verification failed
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("An error occurred while verifying OTP.");
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else {
          clearInterval(interval);
          return prevSeconds;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  const reSendOtp = () => {
    console.log("first");
    setSeconds(10);
  };

  return (
    <div className={styles.login}>
      <div className={styles.login_left}>
        <div className={styles.login_left_header}>
          <h1>Enter your Details</h1>
        </div>
        <div className={styles.ellipses}>
          <div className={styles.ellipses_small}>
            <img src={EllipseSmallSvg} />
          </div>
          <div className={styles.ellipses_main}>
            <img src={EllipseSvg} />
          </div>
        </div>
      </div>
      <div className={styles.login_right}>
        <div className={styles.login_form}>
          <div className={styles.brand_logo}>
            <img src={FrameImg} alt="" />
          </div>
          <div className={styles.login_form_heading}>
            <h1>OTP Verification</h1>
          </div>

          <MuiOtpInputStyled value={otp} onChange={handleOtpChange} />

          <div className={styles.countdown_text}>
            <p>
              Time Remaining:{"  "}
              <span style={{ fontWeight: 600 }}>
                {seconds < 10 ? `0${seconds}` : seconds}
              </span>
            </p>
            <button
              disabled={seconds > 0}
              style={seconds > 0 ? { color: "#DFE3E8" } : { color: "#FF5630" }}
              onClick={reSendOtp}
            >
              RESEND OTP
            </button>
          </div>

          <div className={styles.button}>
            <button onClick={handleOtpVerification}>CONTINUE</button>
          </div>
          <div className={styles.dont_have_account}>
            Want to change your email?
            <span
              onClick={() => navigate(-1)}
              className={styles.change_email_option}
            >
              Click here
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
