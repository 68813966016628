import React from "react";
import styles from "./Footer.module.css";
import FrameImg from "../../assets/Frame.png";
import TwitterSvg from "../../assets/twitter.svg";
import InstaSvg from "../../assets/instagram.svg";
import LinkedSvg from "../../assets/linkedin.svg";
import TiktokSvg from "../../assets/tiktok.svg";
import FaceBookSvg from "../../assets/facebook.svg";
import { Link } from 'react-router-dom';


const Footer = ({scrollToFAQ,scrollToPartners}) => {
  // Handler to scroll to the top
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className={styles.footer}>
     <div className={styles.footer_container}>
     <div className={styles.footer_content}>
        <div className={styles.footer_col}>
          <h3>ShopFromBharat</h3>
          <ul>
            <li>
                <Link to="/about-us">About Us</Link>
            </li>
            <li>
               <Link to="/how-it-works">How it Works ?</Link>
            </li>
            <li>
                <Link to="/" onClick={handleScrollToTop}>Place Order</Link>
            </li>
            <li>Contact Us</li>
            {/* <li>Pricing</li> */}
            <li><Link to="/" onClick={scrollToPartners}>Delivery Partners</Link></li>
          </ul>
        </div>
        <div className={styles.footer_col} style={{width: '-webkit-fill-available'}}>
          <h3>Important Links</h3>
          <ul>
            <li> 
                <Link to="/#faq" onClick={scrollToFAQ}>FAQ</Link>
            </li>
            {/* <li>Prohitibited Items</li>
            <li>Cancellation policy</li> */}
            <li style={{minWidth:"120px;"}}>  <Link to="/privacy-policy">Privacy policy</Link></li> 
            <li style={{minWidth:"120px;"}}><Link to="/refund-policy">Refund policy</Link></li>
          </ul>
        </div>
        <div className={styles.footer_col}>
          <h3>Headquarters</h3>
          <ul>
            <li>Startup Lane, 381, 1st Main Rd, 7th Block, Koramangala, Bengaluru, Karnataka 560095</li>
            <li>Email - info@shopfrombharat.com</li>
            {/* <li>+91-97317 33771 +91 95503 27771</li> */}
           
            {/* <li>Locate Us</li> */}
          </ul>
        </div>
        <div className={styles.footer_col}>
          <h3>Contact Us</h3>
          <div className={styles.footer_input}>
            <input className={styles.input} type="text" placeholder="Enter your Email" />
            <button className={styles.button}>Subscribe</button>
          </div>
          <ul className={styles.socials_list}>
            {/* <li className={styles.social_media}><img src={TwitterSvg} alt="" /></li> */}
            <a href="https://www.instagram.com/shopfrombharat.official" target="_blank" rel="noopener noreferrer">
                <li className={styles.social_media}><img src={InstaSvg} alt="" /></li>
            </a> 
            {/* <li className={styles.social_media}><img src={LinkedSvg} alt="" /></li> */}
            {/* <li className={styles.social_media}><img src={TiktokSvg} alt="" /></li> */}
            <a href='https://www.facebook.com/shopfrombharatofficial' target="_blank" rel="noopener noreferrer"> 
               <li className={styles.social_media}><img src={FaceBookSvg} alt="" /></li>
            </a>
          </ul>
        </div>
      </div>
      <div className={styles.footer_copyright}>
        <div className={styles.footer_brand}>
          <img src={FrameImg} alt="" />
        </div>
        <div className={styles.copyright}>&copy; 2024 All Rights Reserved</div>
      </div>
     </div>
    </div>
  );
};

export default Footer;


