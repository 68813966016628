import React from "react";
import styles from "./Categories.module.css";
import { Link } from "react-router-dom";
import PharmacySvg from "../../assets/Health.svg";
import FashionSvg from "../../assets/clothes.svg";
import KitchenSvg from "../../assets/Kitchen.svg";
import SofaSvg from "../../assets/Sofa.svg";
import FoodSvg from "../../assets/food.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowSvg from "../../assets/Vector.svg";

const items = [
  { id: 1, name: "MEN'S FASHION", itemSrc: FashionSvg },
  { id: 2, name: "WOMEN'S FASHION", itemSrc: FashionSvg },
  { id: 3, name: "MARKETPLACE", itemSrc: KitchenSvg },
  { id: 4, name: "HEALTH & BEAUTY", itemSrc: FoodSvg },
  { id: 5, name: "CONSUMER ELECTRONICS", itemSrc: PharmacySvg },
  { id: 6, name: "LIGHTS & FURNITURE", itemSrc: SofaSvg },
  { id: 7, name: "JEWELS & TIMEPIECES", itemSrc: PharmacySvg },
  { id: 8, name: "ENVIRONMENT FRIENDLY", itemSrc: PharmacySvg },
  { id: 9, name: "CHILDREN STORE", itemSrc: PharmacySvg },
  { id: 10, name: "SPORTS & TOYS", itemSrc: PharmacySvg },
  { id: 11, name: "ACCESSORIES", itemSrc: SofaSvg },
  { id: 12, name: "HOME & KITCHEN", itemSrc: PharmacySvg },
  { id: 13, name: "VEHICLES & PARTS", itemSrc: PharmacySvg },
  { id: 14, name: "INDIAN BRANDS", itemSrc: PharmacySvg },
  { id: 15, name: "LUXURY", itemSrc: PharmacySvg },
];

const Categories = React.forwardRef((props, ref) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    dots: true, // Enable dots
    arrows:false,
    responsive: [
      { breakpoint: 1024, settings: { slidesToShow: 4, slidesToScroll: 4 } },
      { breakpoint: 768, settings: { slidesToShow: 2, slidesToScroll: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };

  return (
    <div className={styles.categories} ref={ref}>
      <div className={styles.categories_container}>
        <div className={styles.categories_header}>
          <h2>What can you order from us?</h2>
          <Link to="/platforms" className={styles.sites_viewall}>
            <div className={styles.sites_view}>View All</div>
            <img className={styles.arrowIcon} src={ArrowSvg} alt="" />
          </Link>
        </div>

        <Slider {...settings} className={styles.slider}>
          {items.map((item) => (
            <div className={styles.category_item} key={item.id}>
              <div className={styles.category_item_image}>
                <img src={item.itemSrc} alt={item.name} />
                <p>{item.name}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
});

export default Categories;






















// import React, { useEffect, useRef, useState } from "react";
// import styles from "./Categories.module.css";
// import { Link } from "react-router-dom";
// import PharmacySvg from "../../assets/Health.svg";
// import FashionSvg from "../../assets/clothes.svg";
// import KitchenSvg from "../../assets/Kitchen.svg";
// import SofaSvg from "../../assets/Sofa.svg";
// import FoodSvg from "../../assets/food.svg";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import ArrowSvg from "../../assets/Vector.svg";

// const items = [
//   { id: 1, name: "MEN'S FASHION", itemSrc: FashionSvg },
//   { id: 2, name: "WOMEN'S FASHION", itemSrc: FashionSvg },
//   { id: 3, name: "MARKETPLACE", itemSrc: KitchenSvg },
//   { id: 4, name: "HEALTH & BEAUTY", itemSrc: FoodSvg },
//   { id: 5, name: "CONSUMER ELECTRONICS", itemSrc: PharmacySvg },
//   { id: 6, name: "LIGHTS & FURNITURE", itemSrc: SofaSvg },
//   { id: 7, name: "JEWELS & TIMEPIECES", itemSrc: PharmacySvg },
//   { id: 8, name: "ENVIRONMENT FRIENDLY", itemSrc: PharmacySvg },
//   { id: 9, name: "CHILDREN STORE", itemSrc: PharmacySvg},
//   { id: 10, name: "SPORTS & TOYS", itemSrc:PharmacySvg},
//   { id: 11, name: "ACCESSORIES", itemSrc: SofaSvg },
//   { id: 12, name: "HOME & KITCHEN", itemSrc: PharmacySvg },
//   { id: 13, name: "VEHICLES & PARTS", itemSrc: PharmacySvg },
//   { id: 14, name: "INDIAN BRANDS", itemSrc: PharmacySvg },
//   { id: 15, name: "LUXURY", itemSrc: PharmacySvg },
// ];
// // const items = [
// //   {
// //     itemSrc: PharmacySvg,
// //     name: "Pharmacy",
// //   },
// //   {
// //     itemSrc: FashionSvg,
// //     name: "Fashion",
// //   },
// //   {
// //     itemSrc: KitchenSvg,
// //     name: "Kitchen Appliances",
// //   },
// //   {
// //     itemSrc: FurnitureSvg,
// //     name: "Furniture",
// //   },
// //   {
// //     itemSrc: GrocerySvg,
// //     name: "Food & Grocery",
// //   }
// // ];

// // Custom Arrow components with MUI icons
// const NextArrow = (props) => {
//   const { onClick } = props;
//   return (
//     <div className={`${styles.arrow} ${styles.next}`} onClick={onClick}>
//       <ChevronRightIcon />
//     </div>
//   );
// };

// const PrevArrow = (props) => {
//   const { onClick } = props;
//   return (
//     <div className={`${styles.arrow} ${styles.prev}`} onClick={onClick}>
//       <ChevronLeftIcon />
//     </div>
//   );
// };
// const Categories = React.forwardRef((props, ref) => {
//   const settings = {
//     infinite: true,
//     speed: 500,
//     slidesToShow: 5,
//     slidesToScroll: 1,
//     dots: false,
//     nextArrow: <NextArrow />, // Custom next arrow
//     prevArrow: <PrevArrow />, // Custom previous arrow
//     responsive: [
//       { breakpoint: 1024, settings: { slidesToShow: 4 } },
//       { breakpoint: 768, settings: { slidesToShow: 2 } },
//       { breakpoint: 480, settings: { slidesToShow: 1 } },
//     ],
//   };

//   return (
//     <div className={styles.categories} ref={ref}>
//       <div className={styles.categories_container}>
//         <div className={styles.categories_header}>
//           <h2>What can you order from us?</h2>
//           <Link to="/platforms" className={styles.sites_viewall}>
//               <div className={styles.sites_view}>View All</div>
//               <img className={styles.arrowIcon} src={ArrowSvg} alt="" />
//           </Link>
//         </div>

//         <Slider {...settings} className={styles.slider}>
//           {items.map((item) => (
//             <div className={styles.category_item} key={item.id}>
//               <div className={styles.category_item_image}>
//                 <img src={item.itemSrc} alt={item.name} />
//                 <p>{item.name}</p>
//               </div>
//             </div>
//           ))}
//         </Slider>
//       </div>
//     </div>
//   );
// });

// export default Categories;



// const Categories = React.forwardRef((props, ref) => {
//   const [tab, setTab] = useState(0);

//   const toggleTab = (val) => {
//     setTab(val);
//   };

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     arrows: false,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 2,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   const sliderRef = useRef(null);

//   const handleNext = () => {
//     sliderRef.current.slickNext();
//   };

//   const handlePrev = () => {
//     sliderRef.current.slickPrev();
//   }; 

//   return (
//     <div className={styles.categories} ref={ref}>
//       <div className={styles.categories_container}>
//         <div className={styles.categories_header}>
//           <div className={styles.heading}>
//             <h2>What can you order from us?</h2>
//           </div>
//         </div>
//         <div className={styles.categories_items}>
//           {items.map((item, index) => (
//             <div className={styles.category_item} key={index}>
//               <div className={styles.category_item_image}>
//                 <img src={item.itemSrc} alt="" />
//                 <p>{item.name}</p>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>     
//     </div>
//   );
// })

// export default Categories;
