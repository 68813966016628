import AjioLogo from './assets/Ajio.png';
import MyntraLogo from './assets/myntra.png'
import NykaaLogo from './assets/nykaa.png'
import DecathlonLogo from './assets/Decathlon.webp'
import FlipkartLogo from './assets/flipkart.png'
import AmazonLogo from './assets/amazon.svg'
import ZaraLogo from './assets/zara.svg'
import AdidasLogo from './assets/Adidas.png'
import HnMLogo from './assets/hnm.png'
import VegNonVegLogo from './assets/vegnonveg.png'
import NNNOWLogo from './assets/nnnow.webp'
import TanishqLogo from './assets/Tanishq.png'
import OnitsukaLogo from './assets/onitsukatiger.png'
import TataCliqLogo from './assets/tatacliq.png'
import ClarksLogo from './assets/clarks.png'
import PumaLogo from './assets/puma.png'
import BirkenstockLogo from './assets/birkenstock.webp'
import CrocsLogo from './assets/crocs.png'
import LenskartLogo from './assets/lenskart.png'
import ReebokLogo from './assets/reebok.webp'
import RedTapeLogo from './assets/redtape.webp'
import ShoppersStopLogo from './assets/shoppersstop.jpg'
import JockeyLogo from './assets/jockey.png'
import KhadiLogo from './assets/khadinatural.webp'
import MokobaraLogo from './assets/mokobara.jpg'
import NeemanLogo from './assets/naamans.webp'
import XYXXLogo from './assets/xyxxcrew.png'
import CloviaLogo from './assets/clovia.png'
import BlueStoneLogo from './assets/bluestone.png'
import LakmeLogo from './assets/lakme.png'
import FirstCryLogo from './assets/FirstCry.png'
import ForestLogo from './assets/ForestEssentials.png'
import AmalaLogo from './assets/amala.webp'
import BabyChakraLogo from './assets/babychakra.png'
import MiviLogo from './assets/mivi.webp'
import SleepwellLogo from './assets/sleepwell.png'
import UniqloLogo from './assets/uniqlo.png'
import VistaprintLogo from './assets/uniqlo.png'
import JabongLogo from './assets/uniqlo.png'



export const platforms = [
  {
    name: "AJIO",
    link: "https://www.ajio.com/",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "MARKETPLACE",
      "HEALTH & BEAUTY",
      "JEWELS & TIME PIECES",
      "ENVIROMENT FRIENDLY",
      "CHILDREN STORE",
      "SPORTS & TOYS",
      "ACCESSORIES",
    ],
    imgSrc: AjioLogo,
  },
  {
    name: "Myntra",
    link: "https://www.myntra.com",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "CONSUMER ELECTRONICS",
      "CHILDREN STORE",
      "ACCESSORIES",
      "LUXURY",
      "PET SUPPLIES"
    ],
    imgSrc: MyntraLogo,
  },
  {
    name: "Nykaa",
    link: "https://www.nykaa.com",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "HEALTH & BEAUTY",
      "ACCESSORIES",
      "LUXURY"
    ],
    imgSrc: NykaaLogo,
  },
  {
    name: "Decathlon",
    link: "https://www.decathlon.in",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "CHILDREN STORE",
      "SPORTS & TOYS",
      "INTERNATIONAL BRANDS IN INDIA"
    ],
    imgSrc: DecathlonLogo,
  },
  {
    name: "UNIQLO",
    link: "https://www.uniqlo.com/in/en/",
    categories: ["MEN'S FASHION", "WOMEN'S FASHION","INTERNATIONAL BRANDS IN INDIA"],
    imgSrc: UniqloLogo,
  },
  {
    name: "Flipkart",
    link: "https://www.flipkart.com",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "MARKETPLACE",
      "HEALTH & BEAUTY",
      "CONSUMER ELECTRONICS",
      "LIGHTS & FURNITURES",
      "CHILDREN STORE",
      "SPORTS & TOYS",
      "ACCESSORIES",
      "HOME & KITCHEN",
      "VEHICLES",
      "PET SUPPLIES",
      "MUSICAL INSTRUMENT",
      "BUSINESS, EQUIPEMENT & SCIENCE"

    ],
    imgSrc: FlipkartLogo,
  },
  {
    name: "Amazon India",
    link: "https://www.amazon.in",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "MARKETPLACE",
      "HEALTH & BEAUTY",
      "CONSUMER ELECTRONICS",
      "LIGHTS & FURNITURES",
      "CHILDREN STORE",
      "SPORTS & TOYS",
      "ACCESSORIES",
      "VEHICLES",
      "PET SUPPLIES",
      "MUSICAL INSTRUMENT",
      "BUSINESS, EQUIPEMENT & SCIENCE"
    ],
    imgSrc: AmazonLogo,
  },
  {
    name: "ZARA India",
    link: "https://www.zara.com/in/",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "MARKETPLACE",
      "CHILDREN STORE",
      "ACCESSORIES",
      "INTERNATIONAL BRANDS IN INDIA"
    ],
    imgSrc: ZaraLogo,
  },
  {
    name: "Adidas India",
    link: "https://www.adidas.co.in",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "CHILDREN STORE",
      "SPORTS & TOYS",
      "ACCESSORIES",
      "INTERNATIONAL BRANDS IN INDIA"
    ],
    imgSrc: AdidasLogo,
  },
  {
    name: "H&M India",
    link: "https://www2.hm.com/en_in/index.html",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "HEALTH & BEAUTY",
      "CHILDREN STORE",
      "ACCESSORIES",
      "INTERNATIONAL BRANDS IN INDIA"
    ],
    imgSrc: HnMLogo,
  },
  {
    name: "VegNonVeg",
    link: "https://www.vegnonveg.com",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "CHILDREN STORE",
      "SPORTS & TOYS",
      "ACCESSORIES",
      "LUXURY"
    ],
    imgSrc: VegNonVegLogo,
  },
  {
    name: "NNNOW",
    link: "https://www.nnnow.com",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "MARKETPLACE",
      "HEALTH & BEAUTY",
      "CHILDREN STORE",
      "ACCESSORIES",
    ],
    imgSrc: NNNOWLogo,
  },
  {
    name: "Tanishq",
    link: "https://www.tanishq.co.in",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "JEWELS & TIME PIECES",
      "ACCESSORIES",
      "INDIAN BRANDS"
    ],
    imgSrc: TanishqLogo,
  },
  {
    name: "Onitsuka Tiger India",
    link: "https://www.onitsukatiger.com/in/en-in/",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "CHILDREN STORE",
      "ACCESSORIES",
    ],
    imgSrc: OnitsukaLogo,
  },
  {
    name: "Tata CLiQ",
    link: "https://www.tatacliq.com",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "MARKETPLACE",
      "CONSUMER ELECTRONICS",
      "LIGHTS & FURNITURES",
      "JEWELS & TIME PIECES",
      "ACCESSORIES",
    ],
    imgSrc: TataCliqLogo,
  },
  {
    name: "Clarks India",
    link: "https://www.clarks.in",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "CHILDREN STORE",
      "SPORTS & TOYS",
      "ACCESSORIES",
    ],
    imgSrc: ClarksLogo,
  },
  {
    name: "PUMA India",
    link: "https://in.puma.com/in/en",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "CHILDREN STORE",
      "SPORTS & TOYS",
      "ACCESSORIES",
      "INTERNATIONAL BRANDS IN INDIA"
    ],
    imgSrc: PumaLogo,
  },
  {
    name: "Birkenstock India",
    link: "https://www.birkenstock.in",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "CHILDREN STORE",
      "ACCESSORIES",
    ],
    imgSrc: BirkenstockLogo,
  },
  {
    name: "Crocs India",
    link: "https://www.crocs.in",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "CHILDREN STORE",
      "ACCESSORIES",
      "INTERNATIONAL BRANDS IN INDIA"
    ],
    imgSrc: CrocsLogo,
  },
  {
    name: "Lenskart",
    link: "https://www.lenskart.com",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "HEALTH & BEAUTY",
      "ACCESSORIES",
      "INDIAN BRANDS"
    ],
    imgSrc: LenskartLogo,
  },
  {
    name: "Reebok India",
    link: "https://reebok.abfrl.in",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "SPORTS & TOYS",
      "ACCESSORIES",
      "INTERNATIONAL BRANDS IN INDIA"
    ],
    imgSrc: ReebokLogo,
  },
  {
    name: "RedTape",
    link: "https://redtape.com",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "SPORTS & TOYS",
      "ACCESSORIES",
      "INDIAN BRANDS"
    ],
    imgSrc: RedTapeLogo,
  },
  {
    name: "Shoppers Stop",
    link: "https://www.shoppersstop.com",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "MARKETPLACE",
      "HEALTH & BEAUTY",
      "CONSUMER ELECTRONICS",
      "LIGHTS & FURNITURES",
      "JEWELS & TIME PIECES",
      "CHILDREN STORE",
      "SPORTS & TOYS",
      "ACCESSORIES",
    ],
    imgSrc: ShoppersStopLogo,
  },
  {
    name: "Jockey India",
    link: "https://www.jockey.in",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "CHILDREN STORE",
      "ACCESSORIES",
      "INTERNATIONAL BRANDS IN INDIA"
    ],
    imgSrc: JockeyLogo,
  },
  {
    name: "Khadi Natural",
    link: "https://www.khadinatural.com",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "HEALTH & BEAUTY",
      "ENVIROMENT FRIENDLY",
      "ACCESSORIES",
    ],
    imgSrc: KhadiLogo,
  },

  {
    name: "Mokobara",
    link: "https://mokobara.com",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "HEALTH & BEAUTY",
      "ENVIROMENT FRIENDLY",
      "ACCESSORIES",
    ],
    imgSrc: MokobaraLogo,
  },
  {
    name: "Neeman's",
    link: "https://neemans.com",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "HEALTH & BEAUTY",
      "ENVIROMENT FRIENDLY",
      "ACCESSORIES",
    ],
    imgSrc: NeemanLogo,
  },
  {
    name: "XYXX",
    link: "https://xyxxcrew.com",
    categories: ["MEN'S FASHION", "ACCESSORIES"],
    imgSrc: XYXXLogo,
  },
  {
    name: "Clovia",
    link: "https://www.clovia.com",
    categories: [
      "WOMEN'S FASHION",
      "MARKETPLACE",
      "HEALTH & BEAUTY",
      "CHILDREN STORE",
      "ACCESSORIES",
      "INDIAN BRANDS"
    ],
    imgSrc: CloviaLogo,
  },
  {
    name: "BlueStone",
    link: "https://www.bluestone.com",
    categories: ["WOMEN'S FASHION", "JEWELS & TIME PIECES", "ACCESSORIES"],
    imgSrc: BlueStoneLogo,
  },
  {
    name: "Lakme",
    link: "https://www.lakmeindia.com/pages/shoppable",
    categories: ["WOMEN'S FASHION", "HEALTH & BEAUTY", "ACCESSORIES"],
    imgSrc: LakmeLogo,
  },
  {
    name: "FirstCry",
    link: "https://www.firstcry.com",
    categories: [
      "MARKETPLACE",
      "HEALTH & BEAUTY",
      "CHILDREN STORE",
      "ACCESSORIES",
    ],
    imgSrc: FirstCryLogo,
  },
  {
    name: "Forest Essentials",
    link: "https://www.forestessentialsindia.com",
    categories: ["HEALTH & BEAUTY", "ENVIROMENT FRIENDLY", "ACCESSORIES","INDIAN BRANDS"],
    imgSrc: ForestLogo,

  },
  {
    name: "Amala Earth",
    link: "https://amala.earth",
    categories: ["HEALTH & BEAUTY", "ENVIROMENT FRIENDLY", "ACCESSORIES","INDIAN BRANDS"],
    imgSrc: AmalaLogo,
  },
  {
    name: "BabyChakra",
    link: "https://www.babychakra.com",
    categories: [
      "HEALTH & BEAUTY",
      "ENVIROMENT FRIENDLY",
      "CHILDREN STORE",
      "ACCESSORIES",
      "INDIAN BRANDS"
    ],
    imgSrc: BabyChakraLogo,
  },
  {
    name: "Mivi",
    link: "https://www.mivi.in",
    categories: ["HEALTH & BEAUTY", "CONSUMER ELECTRONICS", "ACCESSORIES"],
    imgSrc: MiviLogo,
  },
  {
    name: "Sleepwell",
    link: "https://mysleepwell.com",
    categories: ["HEALTH & BEAUTY", "MARKETPLACE", "ACCESSORIES","BUSINESS, EQUIPEMENT & SCIENCE"],
    imgSrc: SleepwellLogo,
  },
  {
    name: "Jabong",
    link: "https://www.jabong.com",
    categories: [
      "MEN'S FASHION",
      "WOMEN'S FASHION",
      "MARKETPLACE",
      "HEALTH & BEAUTY",
      "CHILDREN STORE",
      "ACCESSORIES",
    ],
    imgSrc: JabongLogo,
  },
  {
    name: "Vistaprint",
    link: "https://www.vistaprint.in",
    categories: ["HEALTH & BEAUTY", "MARKETPLACE", "ACCESSORIES"],
    imgSrc: VistaprintLogo,
  },
];
