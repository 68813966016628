import { useEffect,useRef } from "react";
import { useLocation } from 'react-router-dom'; // Import useLocation
import "./App.css";
import Accordion from "./Components/Accordion/Accordion";
import Categories from "./Components/Categories/Categories";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import SearchBar from "./Components/SearchBar/SearchBar";
import Services from "./Components/Services/Services";
import Sites from "./Components/Sites/Sites";
import toast from "react-hot-toast";
import VirtualAddress from "./Components/VirtualAddress/VirtualAddress";
import Partners from "./Components/Partners/Partners";
import Testimonials from "./Components/Testimonials/Testimonials";

function HomePage() {
  const faqRef = useRef(null); // Create a ref for the FAQ section
  const partnersRef = useRef(null);
  const wycoRef = useRef(null)
  const location = useLocation(); // Get the current location

  // Function to scroll to the FAQ section
  const scrollToFAQ = () => {
    if (faqRef.current) {
      faqRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToPartners = () => {
    if (partnersRef.current) {
      partnersRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToWYCO = () => {
    if (wycoRef.current) {
      wycoRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  // Check if we need to scroll to the FAQ on mount
  useEffect(() => {
    if (location.hash === '#faq') { // Check if the URL hash is '#faq'
      scrollToFAQ(); // Scroll to the FAQ section
    } else if (location.hash === '#partners') { // Check if the URL hash is '#partners'
      scrollToPartners(); // Scroll to the Delivery Partners section
    }
    else if (location.hash === '#WYCO'){
      scrollToWYCO();
    }
  }, [location]); 

  return (
    <>
      <div className="app">
        <Header  scrollToWYCO={scrollToWYCO} />
        <Categories ref={wycoRef} />
        <Sites />
        <Services />
        <Testimonials />
        <Partners ref={partnersRef} />
        <Accordion ref={faqRef} />
      </div>
      <Footer scrollToFAQ={scrollToFAQ} scrollToPartners={scrollToPartners} />
    </>
  );
}

export default HomePage;
