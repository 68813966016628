import React, { useState } from "react";
import styles from "../PlaceOrder.module.css";
import { CssTextField } from "../index";
import HistoryIcon from "@mui/icons-material/History";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import payPalIcon from "../../../assets/paypal_icon.jpg";
import LeftArrow from "../../../assets/leftarrow.svg";
import axios from 'axios'
import { useSelector } from "react-redux";
import apiServiceHandler from "../../../service/apiService";
import toast from "react-hot-toast";

const PaymentStep = ({
  onClose,
  handlePaymentSelect,
  handlePaymentSubmit,
  selectedPayment,
  setCurrentStep,
}) => {
  // const [paymentId, setPaymentId] = useState("");
  const paymentMethods = [
    {
      id: 1,
      name: "Saved Options",
      icon: <HistoryIcon />,
    },
    {
      id: 2,
      name: "Debit/Credit Card",
      icon: <CreditCardOutlinedIcon />,
    },
    {
      id: 3,
      name: "Paypal",
      icon: <img src={payPalIcon} alt="PayPal" className={styles.payPalIcon} />,
    },
  ];

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");

      script.src = src;

      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  const order_data = useSelector((state) => state.products.newOrder)
  const totalAmount =  useSelector((state) => state.products.totalAmount)

  const token = localStorage.getItem("BHARAT_TOKEN");
  const createRazorpayOrder = () => {
    let data = JSON.stringify({
      amount: totalAmount*100,
      order_id: order_data?._id
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "http://localhost:8080/api/razorpay/create",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        handleRazorpayScreen(totalAmount*100, response?.data?.payment?._id);
      })
      .catch((error) => {
        console.log("error at", error);
      });
  };

  const handleRazorpayScreen = async (total_amount, paymentId) => {
    const res = await loadScript("https:/checkout.razorpay.com/v1/checkout.js");
    if (!res) {
      alert("Some error at razorpay screen loading");
      return;
    }

    const options = {
      key: "rzp_test_mL8Uac3w1TExqK",
      amount: total_amount,
      currency: "INR",
      name: "shop from bharat",
      description: "payment to shop from bharat pvt ltd",
      image: "",
      handler: async function (response) {
        const res = await apiServiceHandler('POST', `api/razorpay/${paymentId}`, {
          payment_id: response?.razorpay_payment_id,
          status: 'success'
        })
        if (res.status) {
          setCurrentStep('success')
        } else {
          toast.error(res.message)
        }
      },
      modal: {
        ondismiss: function () {
          apiServiceHandler('POST', `api/razorpay/${paymentId}`, {
            payment_id: null,
            status: 'failed'
          });
          toast.error("Payment was unsuccessful.");
        },
      },
      theme: {
        color: "#F4C430",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const [activeMethod, setActiveMethod] = useState(1);

  const toggleMethod = (val) => {
    setActiveMethod(val);
  };

  return (
    <>
      <div className={styles.modal_header}>
        <div className={styles.header_left}>
          <img src={LeftArrow} onClick={() => setCurrentStep("address")} />
          <h2>Select Payment Method</h2>
        </div>
        <button onClick={onClose}>X</button>
      </div>
      <div style={{ display: "flex", gap: "2rem" }}>
        {/* <div className={styles.selected_method}>
          <div className={styles.paymentMethods}>
            {paymentMethods.map((method, index) => (
              <div
                key={index}
                className={`${styles.paymentMethod} ${
                  activeMethod === method.id ? styles.active : ""
                }`}
                onClick={() => toggleMethod(method.id)}
              >
                <span>{method.icon}</span>
                <span className={styles.option_text}>{method.name}</span>
              </div>
            ))}
          </div>
        </div>

        
        <div className={styles.modal_inputs}>
          <div className={styles.modal_header}>
            <h4
              style={{
                color: "#475467",
                fontWeight: 600,
                fontSize: "20px",
                lineHeight: "21px",
              }}
            >
              Debit/Credit Card
            </h4>
          </div>

          <CssTextField className={styles.name_field} label="Card Details" />
          <div className={styles.form_zip_city}>
            <CssTextField
              className={styles.input_field}
              label="Card Expiry"
              required
              // onChange={(e) => setPhone(e.target.value)}
            />

            <CssTextField
              className={styles.input_field}
              label="CVV"
              required
              // onChange={(e) => setPhone(e.target.value)}
            />
          </div>

          <div className={styles.checkboxContainer}>
            <label className={styles.checkbox_label}>
              <input type="checkbox" name="home" /> Save card securely
            </label>
          </div>
        </div> */}
      </div>
      <button className={styles.proceedBtn} onClick={createRazorpayOrder}>
        CONFIRM
      </button>
    </>
  );
};

export default PaymentStep;
